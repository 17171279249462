import usePaddleCheckout from "@lib/usePaddleCheckout";
import { useSession } from "next-auth/client";
import { useRouter } from "next/router";
import styled, { css } from "styled-components";
import { BREAKPOINT_DESKTOP, BREAKPOINT_TABLET } from "../styledMixins";

export type Discount = {
  code: string;
  amount: number;
};

type MembershipProps = {
  type: "tier1" | "tier2" | "tier3";
  productId: string;
  parentPlanName?: string;
  title: string;
  strikeThroughPrice: string;
  price: string;
  checks: Array<{ text: string; hideOnDesktop?: boolean; negative?: boolean }>;
  cta: string;
  jobReadinessNoteHidden?: boolean;
  discount?: Discount;
};

function getReducedPrice(price: string, discount?: Discount) {
  if (!discount) return;
  const reducedPriceNumber =
    Number.parseInt(price.replace("$", ""), 10) * (1 - discount.amount);
  return `$${Math.round(reducedPriceNumber)}`;
}

export function Membership({
  type,
  productId,
  title,
  strikeThroughPrice,
  price,
  checks,
  cta,
  jobReadinessNoteHidden,
  discount,
}: MembershipProps) {
  const [session] = useSession();
  const router = useRouter();

  const reducedPrice = getReducedPrice(price, discount);

  const { openCheckout } = usePaddleCheckout({
    onSuccess: ({ checkout }) => {
      if (!session) {
        router.push(
          `/project/react-job-simulator/welcome?paymentId=${checkout.id}`
        );
      } else {
        router.push("/project/react-job-simulator/welcome?upgrade=1");
      }
    },
  });

  return (
    <X11 type={type}>
      <Frame1707478408>
        <JobSeekerBasic>{title}</JobSeekerBasic>
        <Price>
          <Frame1707478407>
            <PriceStriked>{strikeThroughPrice}</PriceStriked>
            <PriceCurrent>{reducedPrice || price}</PriceCurrent>
          </Frame1707478407>
          <OneTimePayment>One time Payment</OneTimePayment>
        </Price>
      </Frame1707478408>
      <Detail>
        {checks.map((check, index) => (
          <Check
            key={index}
            hideOnDesktop={check.hideOnDesktop}
            negative={check.negative}
          >
            <Component1
              src={
                check.negative
                  ? "/img/ion-close-circle-sharp-5@2x.png"
                  : "/img/component-1-64@2x.png"
              }
              alt="Component 1"
            />
            <DiscoverHowProfess>{check.text}</DiscoverHowProfess>
          </Check>
        ))}
      </Detail>
      <Button2
        onClick={() =>
          openCheckout({ productId, discountCode: discount?.code })
        }
      >
        {cta}
      </Button2>
      <div
        style={jobReadinessNoteHidden ? { visibility: "hidden" } : undefined}
      >
        * To provide a meaningful job-readiness assessment, you have to finish
        at least 10 of the 21 tasks.
      </div>
    </X11>
  );
}

const X11 = styled.div<{ type: MembershipProps["type"] }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  gap: 36px;
  background-color: ${({ type }) =>
    type === "tier2" ? "var(--white)" : "var(--ebony-clay)"};
  color: ${({ type }) =>
    type === "tier2" ? "var(--ebony-clay)" : "var(--white)"};
  border-radius: 10px;

  ${({ type }) =>
    type === "tier2" &&
    css`
      ${Button2} {
        color: var(--white);
        background-color: var(--amaranth);
        border: none;
        box-shadow: 3px 3px 0px #000000;
      }
    `}

  @media (${BREAKPOINT_TABLET}) {
    width: 48%;
  }

  @media (${BREAKPOINT_DESKTOP}) {
    width: 31%;
  }
`;

const Frame1707478408 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const JobSeekerBasic = styled.div`
  font-family: var(--font-family-space_grotesk);
  font-size: var(--font-size-xxl);
  font-weight: 700;
  text-align: center;
  letter-spacing: 0;
  line-height: 45px;
`;

const JoiningFromPolandGet50Off = styled.p`
  margin-top: 25px;
  text-align: center;
  letter-spacing: 0.28px;
  line-height: 29.5px;
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs);
  font-weight: 500;
`;

const Price = styled.div`
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

const Frame1707478407 = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
`;

const PriceStriked = styled.div`
  font-family: var(--font-family-inter);
  font-size: 35px;
  font-weight: 500;
  text-decoration: line-through;
`;

const PriceCurrent = styled.div`
  font-family: var(--font-family-inter);
  font-size: 45px;
  font-weight: 700;
`;

const OneTimePayment = styled.div`
  font-family: var(--font-family-inter);
  font-size: 19.7px;
  font-weight: 500;
  width: 100%;
  text-align: center;
  letter-spacing: 0;
  line-height: 28.1px;
`;

const Detail = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 21px;
`;

const Button2 = styled.button`
  width: 100%;
  height: 80px;
  margin-top: auto;
  border-radius: 5px;
  border: 1px solid var(--white);
  box-shadow: 0px 23.91913604736328px 46.43126678466797px #a6445f66;
  font-family: var(--font-family-inter);
  font-size: 26px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.42px;
  white-space: nowrap;
`;

const Check = styled.div<{ hideOnDesktop?: boolean; negative?: boolean }>`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  /* ${({ hideOnDesktop, negative }) =>
    (hideOnDesktop || negative) &&
    css`
      @media (min-width: 768px) {
        display: none;
      }
    `} */
`;

const Component1 = styled.img`
  width: 34px;
  height: 34px;
  margin-top: 0;
`;

const DiscoverHowProfess = styled.p`
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28.1px;
`;
