import { useSession } from "next-auth/client";
import isProduction from "./isProduction";
import type { User } from "@lib/data.types";
import { useAnalytics } from "@components/analytics";
import { useRouter } from "next/router";

declare global {
  interface Window {
    Paddle: any;
  }
}

type OnSuccessParams = {
  checkout: {
    id: string;
    prices: { customer: { total: string; currency: string } };
  };
  product: { name: string };
};

type UsePaddleCheckoutParams = {
  onSuccess?: (params: OnSuccessParams) => void;
};

type UsePaddleCheckoutReturnType = {
  openCheckout: (params: { productId: string; discountCode?: string }) => void;
};

type PaddleCheckoutEvent = {
  event: string;
  eventData: {
    product: {
      name: string;
    };
    user?: {
      id: string;
      email: string;
      country: string;
    };
    error?: {
      error: string;
      error_type: string;
    };
    payment_method?: string;
  };
};

type UsePaddleCheckout = (
  params?: UsePaddleCheckoutParams
) => UsePaddleCheckoutReturnType;

const usePaddleCheckout: UsePaddleCheckout = ({ onSuccess } = {}) => {
  const router = useRouter();
  const [session] = useSession();
  const { trackPlausibleEvent, trackPiwikEvent } = useAnalytics();

  const successCallback = (data: OnSuccessParams) => {
    // track purchase event on Meta
    if (window.fbq) {
      window.fbq("track", "Purchase", {
        content_name: data.product.name,
        value: Number.parseFloat(data.checkout.prices.customer.total),
        currency: data.checkout.prices.customer.currency,
        content_type: "product",
      });
    }
    if (onSuccess) {
      onSuccess(data);
    }
  };

  const openCheckout = ({
    productId,
    discountCode,
  }: {
    productId: string;
    discountCode?: string;
  }) => {
    const user = session?.user as User;
    if (!isProduction()) {
      window.Paddle.Environment.set("sandbox");
    }
    window.Paddle.Setup({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      vendor: Number.parseInt(process.env.NEXT_PUBLIC_PADDLE_VENDOR_ID!),
      eventCallback: function (data: PaddleCheckoutEvent) {
        trackPlausibleEvent("Checkout", {
          event: data.event,
          product: data.eventData.product?.name,
          paymentMethod: data.eventData.payment_method,
          email: data.eventData.user?.email,
          userId: data.eventData.user?.id,
          country: data.eventData.user?.country,
          error: data.eventData.error?.error,
          errorType: data.eventData.error?.error_type,
        });
        trackPiwikEvent({
          category: "Checkout",
          action: data.event,
          dimensions: {
            product: data.eventData.product?.name,
            paymentMethod: data.eventData.payment_method,
            email: data.eventData.user?.email,
            userId: data.eventData.user?.id,
            country: data.eventData.user?.country,
            error: data.eventData.error?.error,
            errorType: data.eventData.error?.error_type,
          },
        });
      },
    });
    window.Paddle.Checkout.open({
      product: isProduction() ? productId : "9887",
      email: user?.email,
      passthrough: JSON.stringify({ userId: user?.id }),
      coupon: discountCode || router.query.coupon,
      allowQuantity: false,
      successCallback,
    });
  };

  return { openCheckout };
};

export default usePaddleCheckout;
