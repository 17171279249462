import styled from "styled-components";
import {
  InterWhite26px,
  SpacegroteskBoldWhite55px,
  TextStyle,
} from "../styledMixins";
import { Discount, Membership } from "./membership";
import { NextRouter, useRouter } from "next/router";
import { useEffect, useState } from "react";

enum ProductIds {
  tier1 = "645447",
  tier2 = "592859",
  tier3 = "866943",
}

export type MembershipType = "tier1" | "tier2" | "tier3";

type Membership = {
  type: MembershipType;
  productId: string;
  title: string;
  strikeThroughPrice: string;
  price: string;
  checks: Array<{ text: string; hideOnDesktop?: boolean; negative?: boolean }>;
  cta: string;
  jobReadinessNoteHidden?: boolean;
  discount?: Discount;
};

export const memberships: Record<MembershipType, Membership> = {
  tier1: {
    type: "tier1",
    productId: ProductIds.tier1,
    title: "Job Seeker Basic",
    strikeThroughPrice: "$199",
    price: "$99",
    cta: "Join Basic",
    jobReadinessNoteHidden: true,
    checks: [
      { text: "Discover how professional teams are structured" },
      { text: "Discover real-world team processes" },
      { text: "Learn how to work with a professional Git workflow" },
      { text: "Discover professional code/folder structures" },
      { text: "Learn how to work through tasks on a Kanban board" },
      { text: "Learn how to build features based on Figma designs" },
      { text: "Learn how to write automated tests with Cypress" },
      { text: "Get access and support in a private Discord" },
      { text: "Instant lifetime access including future updates" },
      { text: "7 day money back guarantee" },
      { text: "Limited support by AI mentor (5 messages per day)" },
      { text: "Eligible for Job-Connector program" },
      {
        text: "Over-the-shoulder walkthrough videos",
        negative: true,
      },
      {
        text: "Get a job-readiness assessment based on your code",
        negative: true,
      },
      {
        text: "Get a personalized roadmap based on your assessment",
        negative: true,
      },
    ],
  },
  tier2: {
    type: "tier2",
    productId: ProductIds.tier2,
    title: "Job Seeker Fast Track",
    strikeThroughPrice: "$299",
    price: "$179",
    cta: "Join Fast Track",
    jobReadinessNoteHidden: true,
    checks: [
      { text: "Discover how professional teams are structured" },
      { text: "Discover real-world team processes" },
      { text: "Learn how to work with a professional Git workflow" },
      { text: "Discover professional code/folder structures" },
      { text: "Learn how to work through tasks on a Kanban board" },
      { text: "Learn how to build features based on Figma designs" },
      { text: "Learn how to write automated tests with Cypress" },
      { text: "Get access and support in a private Discord" },
      { text: "Instant lifetime access including future updates" },
      { text: "7 day money back guarantee" },
      { text: "Unlimited support by AI mentor" },
      { text: "Eligible for Job-Connector program" },
      { text: "Over-the-shoulder walkthrough videos" },
      {
        text: "Get a job-readiness assessment based on your code",
        negative: true,
      },
      {
        text: "Get a personalized roadmap based on your assessment",
        negative: true,
      },
    ],
  },
  tier3: {
    type: "tier3",
    productId: ProductIds.tier3,
    title: "Job Seeker Expert",
    strikeThroughPrice: "$499",
    price: "$249",
    cta: "Join Expert",
    checks: [
      { text: "Discover how professional teams are structured" },
      { text: "Discover real-world team processes" },
      { text: "Learn how to work with a professional Git workflow" },
      { text: "Discover professional code/folder structures" },
      { text: "Learn how to work through tasks on a Kanban board" },
      { text: "Learn how to build features based on Figma designs" },
      { text: "Learn how to write automated tests with Cypress" },
      { text: "Get access and support in a private Discord" },
      { text: "Instant lifetime access including future updates" },
      { text: "7 day money back guarantee" },
      { text: "Unlimited support by AI mentor" },
      { text: "Eligible for Job-Connector program" },
      { text: "Over-the-shoulder walkthrough videos" },
      { text: "Get a job-readiness assessment based on your code *" },
      { text: "Get a personalized roadmap based on your assessment *" },
    ],
  },
};

type ParsedDiscount = {
  code: string;
  amount: number;
  productIds: string[];
};

function parseDiscounts(discountQueryParam: string) {
  // add the missing quote and bracket that were removed on the server
  const parsedDiscounts: ParsedDiscount[] = JSON.parse(
    atob(discountQueryParam)
  );
  if (Array.isArray(parsedDiscounts)) {
    return parsedDiscounts;
  }
  return [parsedDiscounts];
}

function getDiscounts(discountQueryParam: NextRouter["query"]["discount"]) {
  if (!discountQueryParam || Array.isArray(discountQueryParam)) {
    return;
  }

  try {
    const parsedDiscounts = parseDiscounts(discountQueryParam);
    const discounts = parsedDiscounts.map((d) => {
      const discount: ParsedDiscount = {
        ...d,
        amount: d.amount / 100,
        productIds: d.productIds || Object.values(ProductIds),
      };
      return discount;
    });
    return discounts;
  } catch (e) {
    console.error(e);
  }
}

function getDiscountForMemberShip(
  discounts: ParsedDiscount[],
  membership: Membership
) {
  return discounts.find((d) => d.productIds.includes(membership.productId));
}

export function MembershipSection() {
  const router = useRouter();
  const [discounts, setDiscounts] = useState<ParsedDiscount[]>([]);

  useEffect(() => {
    const discounts = getDiscounts(router.query.discount);
    if (discounts) {
      setDiscounts(discounts);
    }
  }, [router.query.discount]);

  return (
    <X12Pricing id="membership">
      <Title1>
        <YourFirstReactJobStartsHere5>
          Simple pricing
        </YourFirstReactJobStartsHere5>
        <GetTheKnowledgeS2>
          Choose your membership level below and get instant lifetime access to
          the React Job Simulator.
        </GetTheKnowledgeS2>
      </Title1>
      <Pricing>
        <Membership
          {...memberships.tier3}
          discount={getDiscountForMemberShip(discounts, memberships.tier3)}
        />
        <Membership
          {...memberships.tier2}
          discount={getDiscountForMemberShip(discounts, memberships.tier2)}
        />
        <Membership
          {...memberships.tier1}
          discount={getDiscountForMemberShip(discounts, memberships.tier1)}
        />
      </Pricing>
    </X12Pricing>
  );
}

const X12Pricing = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 55px 20px;
  align-items: center;
  gap: 42px;
  border-radius: 10px;
  scroll-margin-top: 80px;
`;

const Title1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 196px;
  gap: 31px;
`;

const YourFirstReactJobStartsHere5 = styled.div`
  ${SpacegroteskBoldWhite55px}
  width: 100%;
  text-align: center;
`;

const GetTheKnowledgeS2 = styled.p`
  ${TextStyle}
  ${InterWhite26px}
  text-align: center;
`;

const Pricing = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 28px;
`;
